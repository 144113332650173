<template>
  <section class="author-form-section">
  <v-container>
    <v-card class="pa-4 mb-4 ">
      <v-card-title class="form-title">Ajouter un Auteur</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="addAuthor" ref="addForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="newAuthor.name"
            label="Nom"
            :rules="nameRules"
            required
            outlined
            class="form-field"
          ></v-text-field>
          <VueEditor
        v-model="newAuthor.bibliography"
        auto-grow
        outlined
        label="Biographie"
       
        placeholder="Biographie"
      >
      </VueEditor>

          <v-card-actions class="form-actions">
            <v-btn type="submit" color="info" :loading="loading.add" :disabled="!valid || loading.add">
              Ajouter
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <!--DATA TABLE-->
    <v-card >
        <v-card-title>Liste des Livres</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="authors"
      class="elevation-1 "
      :search="search"
      :no-data-text="noDataText"
    >
      
     <!-- Using the stripHtmlTags method -->
     <template v-slot:item.bibliography="{ item }">
          {{ stripHtmlTags(item.bibliography) }}
        </template>

      <template v-slot:item.name="{ item }">
        <span @click="navigateToAuthorBooks(item.id)" class="author-name">{{ item.name }}</span>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon @click="editAuthor(item)" class="edit-icon">mdi-pencil</v-icon>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon @click="confirmDeleteAuthor(item)" class="delete-icon">mdi-delete</v-icon>
      </template>
    </v-data-table>
    </v-card>

    <v-dialog v-model="editDialog" max-width="700px">
      <v-card>
        <v-card-title class="form-title">Modifier l'Auteur</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="updateAuthor" ref="editForm" v-model="valid" lazy-validation>
            <v-text-field
              v-model="currentAuthor.name"
              label="Nom"
              :rules="nameRules"
              required
              outlined
              class="form-field"
            ></v-text-field>

            <v-text-field
              v-model="currentAuthor.bibliography"
              label="Biographie"
              :rules="bibliographyRules"
              required
              outlined
              class="form-field"
            ></v-text-field>

            <v-card-actions class="form-actions">
              <v-btn type="submit" color="info" :loading="loading.edit" :disabled="!valid || loading.edit">
                Modifier
              </v-btn>
              <v-btn color="error" @click="editDialog = false">Annuler</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Confirmation Dialog -->
    <v-row justify="center">
      <v-dialog v-model="deleteDialog" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer cet auteur?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
            @click="deleteDialog = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="deleteAuthor">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</section>
</template>

<script>
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import Constant from "@/utils/constants";
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      valid: false,
      search: '',
      noDataText: 'Aucun auteur trouvé',
      authors: [],
      headers: [
        { text: 'Nom', value: 'name' },
        { text: 'Biographie', value: 'bibliography' },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
      ],
      newAuthor: {
        name: '',
        bibliography: '',
      },
      currentAuthor: {
        id: null,
        name: '',
        bibliography: '',
      },
      editDialog: false,
      deleteDialog: false,
      loading: {
        add: false,
        edit: false,
        fetch: false,
        delete: false,
      },
      authorToDelete: null,
      nameRules: [
        v => !!v || 'Nom est requis',
      ],
      bibliographyRules: [
        v => !!v || 'Biographie est requis',
      ],
    };
  },
  computed: {
    quillOptions() {
      return {
        placeholder: 'Inserez Votre text ici...' 
      };
    }
  },
  methods: {
    // Method to strip HTML tags from the bibliography
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    },
    async fetchAuthors() {
      this.loading.fetch = true;
      try {
        const response = await fetch(Constant.QORANI + 'library/author/getall');
        this.authors = await response.json();
      } catch (error) {
        // console.error('Error fetching authors', error);
      } finally {
        this.loading.fetch = false;
      }
    },
    async addAuthor() {
    if (!this.$refs.addForm.validate()) {
      return;
    }
    this.loading.add = true;
    try {
      const response = await fetch(Constant.QORANI + 'library/author/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.newAuthor),
      });
      const newAuthor = await response.json();
      this.authors.push(newAuthor);
      this.$refs.addForm.reset();
      
      // Clear the Quill editor by resetting newAuthor.bibliography
      this.newAuthor.bibliography = '';
    } catch (error) {
      // console.error('Error adding author', error);
    } finally {
      this.loading.add = false;
    }
  },
    editAuthor(author) {
      this.currentAuthor = { ...author };
      this.editDialog = true;
    },
    async updateAuthor() {
      if (!this.$refs.editForm.validate()) {
        return;
      }
      this.loading.edit = true;
      try {
        await fetch(Constant.QORANI + `library/author/edit/${this.currentAuthor.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.currentAuthor),
        });
        this.fetchAuthors();
        this.editDialog = false;
      } catch (error) {
        // console.error('Error updating author', error);
      } finally {
        this.loading.edit = false;
      }
    },
    confirmDeleteAuthor(author) {
      this.authorToDelete = author;
      this.deleteDialog = true;
    },
    async deleteAuthor() {
      this.loading.delete = true;
      try {
        await fetch(Constant.QORANI + `library/author/delete/${this.authorToDelete.id}`);
        this.fetchAuthors();
        this.deleteDialog = false;
      } catch (error) {
        // console.error('Error deleting author', error);
      } finally {
        this.loading.delete = false;
        this.authorToDelete = null;
      }
    },
    navigateToAuthorBooks(authorId) {
      this.$router.push({ path: `/author/${authorId}/books` });
    },
  },
 
  mounted() {
    this.fetchAuthors();
  },
};
</script>

<style scoped>
/* Base styles */
.author-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling for form and data table */
.v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Clean white background */
}

/* Input field styling */
.form-field {
  margin-bottom: 16px; /* Spacing between fields */
}

/* Button styling */
.v-btn {
  border-radius: 6px;
 
  margin-top: 20px;

}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Increased spacing for dividers */
}

/* Data table styling */
.elevation-1 {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for data table */
  border-radius: 12px; /* Rounded corners */
}

.elevation-1 thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333; /* Darker text for contrast */
  font-weight: 500; /* Slightly bold */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
}

/* Icon styling */
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

/* Search field styling */
.v-text-field[v-model="search"] {
  max-width: 350px; /* Slightly wider search field */
}

/* Author name styling */
.author-name {
  cursor: pointer;
  color: #1976d2; /* Link color */
  text-decoration: underline; /* Underline for links */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .author-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }

  .v-btn {
    width: 100%; /* Full width buttons */
  }

  .v-card {
    margin-bottom: 15px; /* Adjusted margin for cards */
  
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 45%; /* Allow two items per row on larger screens */
    margin: 0 2.5%; /* Horizontal margin for spacing */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for desktop */
  }
}

/* Dialog styling */
.v-dialog .v-card {
  border-radius: 12px; /* Rounded corners for dialog */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for dialog */
}

.v-card-title {
  font-weight: 500; /* Bold title */
}
</style>


